import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.svg"
import { Link } from "gatsby"
import Social from "./social"
import "../styles/theme.scss"

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <footer className="c-footer-custom o-content u-text-center u-flex-center " style={{ flexDirection: "column", height: "100%" }}>
                    <div className="c-footer__logo">
                        <a className="c-logo u-center" href="https://canada.uninterrupted.com">
                            <img src={Uninterrupted} alt={this.props.global.title} />
                        </a>
                    </div>
                    <div style={{ width: "800px", height: "455px",  padding: "20px"}}>
                        <iframe title="carousel-video" className="c-custom-video_frame"
                            src={`https://www.youtube.com/embed/cfS5Cuk_cZM`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <Social type="footer"></Social>

                </footer>
            </>
        );
    }
};