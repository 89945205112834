import React from "react"
import PropTypes from "prop-types"
import Footer from "../components/customfooter"
import "../styles/theme.scss"
import { detect } from 'detect-browser';
import { StaticQuery, graphql } from "gatsby"

const Layout = ({ data, children, pathname }) => {
    let global = data.contentfulWebsite;
    // TODO: Move to Contentful
    let newsletter = {
        "url": "/",
        "title": "Stay in Touch"
    };

    return (
        <>
            {detect().name === "ie" || detect().name === "msie" ? (
                <div className="u-text-large">Your browser is not supported. Please upgrade your browser to view this website.</div>
            ) : (
                <>
                    <Footer global={global} pathname={pathname} />
                </>
            )}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

const LayoutQuery = (props) => (
    <StaticQuery query={pageQuery} render={data => <Layout data={data} children={props.children} pathname={props.pathname} />} />
);

export default LayoutQuery;

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        partnerTitle
        shareImage {
            file {
                url
            }
        }
        navigation {
            title
            link
        }
        footerNavigation {
            title
            link
        }
    }
}
`;