import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/customlayout";

const Home = ({ data, location }) => {
    let global = data.contentfulWebsite;
    
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
        </Layout>
    );
};

export default Home

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        videoCtaText
        podcastCtaText
        tagline
        partnerTitle
        shareImage {
            file {
                url
            }
        }
    }
    contentfulHomepage(website: {title: {eq: "UNINTERRUPTED Canada"}}) {
        aboutFilmTitle
        aboutFilmDescription {
            raw
        }
        firstImage {
            resize(width: 300) {
                src
            }
        }
        secondImage {
            resize(width: 1900) {
                src
            }
        }
        cards {
            title
            subtitle
            buttonText
            image {
                resize(width: 1900) {
                    src
                }
            }
            url
        }
    }
}
`;